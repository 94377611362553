export const events = [
    {
        date: "Apr 03, 2025",
        title: "Voxxeddays Amsterdam",
        url: "https://amsterdam.voxxeddays.com/",
        image: require("../assets/images/img-6.png"),
        live: true,
        free: false,
        soldOut: false
    },
    {
        date: "Nov 20, 2024",
        title: "Prompt Engineering Conference",
        url: "https://promptengineering.rocks/",
        image: require("../assets/images/img-13.png"),
        live: false,
        free: true,
        soldOut: false
    },{
        date: "Oct 26, 2024",
        title: "FamilyBit festival",
        url: "https://www.sogeti.nl/",
        image: require("../assets/images/img-12.png"),
        live: true,
        free: false,
        soldOut: false
    },{
        date: "Oct 11, 2024",
        title: "Devoxx Belgium",
        url: "https://www.devoxx.be/schedule/speaker-details/?id=4381",
        image: require("../assets/images/img-6.png"),
        live: true,
        rating: 4.63,
        soldOut: true
    },
    {
        date: "Sep 24, 2024",
        title: "QXDay 2024",
        url: "https://www.sogeti.nl/events/qxday2024",
        image: require("../assets/images/img-9.png"),
        live: true,
        soldOut: false,
        multipleSessions: 3
    },
    {
        date: "Sep 05, 2024",
        title: "ODSC",
        url: "https://odsc.com/speakers/supercharge-your-productivity-mastering-prompt-engineering/",
        image: require("../assets/images/img-5.png"),
        live: false,
    },
    {
        date: "Jun 06, 2024",
        title: "Microsoft Envision",
        url: "https://pulse.microsoft.com/nl-nl/microsoft-envision/",
        image: require("../assets/images/img-7.png"),
        live: true,
    },
    {
        date: "Nov 21, 2023",
        title: "JCon World",
        url: "https://jconworld2023.sched.com/speaker/jeroen.egelmeers",
        image: require("../assets/images/img-5.png"),
        live: false,
    },
    {
        date: "Oct 21, 2023",
        title: "Capgemini Innovation Days",
        url: "https://jconworld2023.sched.com/speaker/jeroen.egelmeers",
        image: require("../assets/images/img-4.png"),
        live: true,
        multipleSessions: 2,
    },
    {
        date: "Oct 12, 2023",
        title: "Prompt Engineering Conference",
        url: "https://2023.promptengineering.rocks/",
        image: require("../assets/images/img-5.png"),
        live: false,
    },
    {
        date: "Oct 12, 2023",
        title: "Frontmania 2023",
        url: "https://frontmania.com/programme/",
        image: require("../assets/images/img-1.png"),
        live: true,
    },
    {
        date: "Sep 19, 2023",
        title: "QXDay 2023",
        url: "https://www.sogeti.nl/events/qxday2023",
        image: require("../assets/images/img-2.png"),
        live: true,
        multipleSessions: 3,
    },
    {
        date: "Jun 28, 2023",
        title: "Frontend Lightning Talks",
        url: "https://www.youtube.com/watch?v=omhI9p7xCy8",
        image: require("../assets/images/img-5.png"),
        live: false,
    },
    {
        date: "Oct 06, 2022",
        title: "Co-host QXDay 2022",
        url: "https://www.sogeti.nl/events/qxday2022",
        image: require("../assets/images/img-3.png"),
        live: true,
    },
    {
        date: "Apr 23, 2022",
        title: "Carrierebeurs",
        url: "https://mijn.carrierebeurs.nl/sprekers/jeroen-egelmeers/item41410",
        image: require("../assets/images/img-1.png"),
        live: true,
    },
    {
        date: "Nov 04, 2021",
        title: "J-fall 2021",
        url: "https://nljug.org/wp-content/uploads/2021/12/JAM04.pdf",
        image: require("../assets/images/img-8.png"),
        live: true,
    },
    {
        date: "Oct 07, 2021",
        title: "Co-host QXDay 2021",
        url: "https://www.sogeti.nl/events/qxday2021",
        image: require("../assets/images/img-3.png"),
        live: true,
    },
    {
        date: "Jun 19, 2021",
        title: "Sogeti Got Talent",
        url: "https://www.sogeti.nl/",
        image: require("../assets/images/img-5.png"),
        multipleSessions: 2,
        live: false,
    },
    {
        date: "Oct 08, 2020",
        title: "QXDay",
        url: "https://www.sogeti.nl/events/qx-day-2020",
        image: require("../assets/images/img-2.png"),
        live: true,
    },
]