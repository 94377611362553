import React, {useEffect} from 'react';
import './App.css';
import Footer from "./components/Footer";
import HeaderSmall from "./components/HeaderSmall";
import Services from "./components/Services";
import {events} from "./objects/events";

function Events() {
    useEffect(() => {
        document.title = "Events | Jeroen Egelmeers | Prompt Engineering Advocate | Public Speaker"
    }, []);

    const pastEvent = (date: string) => {
        const eventDate = (new Date(date)).setHours(0, 0, 0, 0);
        const currentDate = (new Date()).setHours(0, 0, 0, 0);
        return eventDate < currentDate;
    }

    const upcomingEvents = events.filter(event => !pastEvent(event.date));
    const pastEvents = events.filter(event => pastEvent(event.date));

    upcomingEvents.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    pastEvents.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return (
    <div className="App flex flex-col min-h-screen">
        <HeaderSmall headerText="Events" />

        <div className="clearfix"></div>

        <div className={"flex-grow"}>
            <div className={"max-container mx-auto"}>
                <Services events={upcomingEvents} title={"Upcoming events"} description={`Find me on the conferences or events below!`} id={"upcoming-events"} />
                <Services events={pastEvents} title={"Past events"} description={`Events I have proudly contributed to!`} id={"past-events"} pagination={true} />
            </div>
        </div>

        <Footer />
    </div>
  );
}

export default Events;
